import emailjs from "@emailjs/browser";

const sendMail = async (user, data) => {
    const templateParams = {
        ...user, ...data, site: 'punting-cambridge.com'
    };

    emailjs.send("service_trchi78", "template_p2clgts", templateParams, "p_cVt0dKcWvYETxXC").then(function (response) {
        console.log('SUCCESS!', response.status, response.text);
    }, function (err) {
        console.log('FAILED...', err);
    }); 
}

export default sendMail