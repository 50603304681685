import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setBoat, setDate, setGroup, setPrice, setTime, setType } from '../../redux/slice/data'

import bgImage from '../../asset/images/background image/banner.jpeg'


function Home() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { date, price } = useSelector(state => state.data)
    const { data } = useSelector(state => state)


    const queryParam = new URLSearchParams(window.location.search.replace(/amp;/g, ''))
    const type = Number(queryParam.get('type'))
    const group = Number(queryParam.get('group'))
    const boat = Number(queryParam.get('boat'))




    useEffect(() => {
        if (type === 1) {
            dispatch(setType('Shared'))
        } else if (type === 2) {
            dispatch(setType('Private'))
        } else if (type === 3) {
            dispatch(setType('Hen Party'))
        } else if (type === 4) {
            dispatch(setType('Evening'))
        }
        if (group === 1) {
            dispatch(setGroup('2 People'))
        } else if (group === 2) {
            dispatch(setGroup('3 People'))
        } else if (group === 3) {
            dispatch(setGroup('6 People'))
        } else if (group === 4) {
            dispatch(setGroup('6 People (Max 2 adults)'))
        } else if (group === 5) {
            dispatch(setGroup('9 People'))
        } else if (group === 6) {
            dispatch(setGroup('12 People'))
        }
        boat && dispatch(setBoat(Number(boat)))
        // eslint-disable-next-line
    }, [type, group])

    const [step, setStep] = useState(1)

    const timeSlotes = [
        { hour: 10, minute: 45 },
        { hour: 11, minute: 15 },
        { hour: 11, minute: 45 },
        { hour: 12, minute: 15 },
        { hour: 12, minute: 45 },
        { hour: 13, minute: 15 },
        { hour: 13, minute: 45 },
        { hour: 14, minute: 15 },
        { hour: 14, minute: 45 },
        { hour: 15, minute: 15 },
        { hour: 15, minute: 45 },
        { hour: 16, minute: 15 },
        { hour: 16, minute: 45 },
        { hour: 17, minute: 15 },
        { hour: 17, minute: 45 },
        { hour: 18, minute: 15 },
    ]


    return (
        <div className='h-screen w-full flex justify-center items-center bg-no-repeat bg-cover  bg-bottom' style={{ backgroundImage: `url(${bgImage})` }}>
            <div className="w-[640px]  p-2 bg-primary">
                <p className='text-lg font-medium text-center text-white '>Please Select Your {step === 1 && 'Date'} {step === 2 && 'Time'} </p>
                <div className="space-y-3">
                    <Details />
                    <div className="w-full  bg-white">

                        {
                            step === 1 && <div className="flex justify-center items-center p-10">
                                <Calendar onChange={e => { 
                                     if(data.type === 'Shared'){
                                         window.alert("Sorry we are fully booked until september")
                                        window.location.href = 'https://punting-cambridge.com'
                                    }
                                    else {
                                        dispatch(setDate(e));
                                        setStep(2)
                                        dispatch(setPrice())
                                    }
                                     }} value={date} />
                            </div>
                        }
                        {
                            step === 2 &&
                            <div className='flex justify-center items-center flex-wrap gap-3'>

                                {timeSlotes.map((e, index) => <div
                                    key={index}
                                    onClick={() => {
                                        const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
                                        const bookingDate = new Date(new Date(date).setHours(e.hour, e.minute))
                                        if (nextDay > bookingDate) {
                                            window.alert("We're sorry, but you cannot proceed with the booking within the next 24 hours. In order to provide the best service and ensure all preparations are made, we require at least 24 hours advance notice for bookings.")
                                            return
                                        }
                                        dispatch(setTime(e))
                                        navigate('/checkout')
                                    }}
                                    className="w-[45%] h-12 border flex rounded-xl overflow-hidden">
                                    <div className="text-white w-1/3 h-full bg-primary flex justify-center items-center">
                                        <p>{e.hour}:{e.minute}</p>
                                    </div>
                                    <div className="w-2/3 h-full flex justify-center items-center">£{price}</div>
                                </div>)}
                            </div>
                        }



                        {
                            step !== 1 && <button className='border border-primary w-48 h-10 m-5' onClick={() => { setStep(step - 1) }}>Back</button>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home

const Details = () => {
    const { type, group } = useSelector(state => state.data)
    return (
        <div className="w-full  bg-white mt-5 p-3 pl-5 flex flex-col justify-center">
            <div className="flex font-bold"> <p className='w-28'>Tour:</p> <p>Punting</p> </div>
            <div className="flex text-sm"> <p className='w-28'>Type:</p> <p>{type}</p> </div>
            <div className="flex text-sm"> <p className='w-28'>Group Size:</p> <p>Ticket - Valid for up to {group}</p> </div>
        </div>
    )
}