import axios from 'axios'
import React, { useEffect } from 'react'
import { PayPalButton } from 'react-paypal-button-v2'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import backendIP from '../../BackendIP'
import Details from '../../shared/Details'
import sendMail from './sendemail'

function Payment() {
  const state = useSelector(state => state)
  const {name,email,mobileNo} = state.user
  const {date,type,group,boat,price} = state.data


  const BookTours = ()=>{
    axios.post(`${backendIP}/tours`,
        {name, email,mobileNo, date : new Date(date).toString(), type, group, boat,price})
        .then(res=>{
          window.alert(res.data.message)
        })
  }

  

  const navigate = useNavigate()
  const createOrder = async () => {
    return axios.post(`${backendIP}/payment/create-order`, { price: state.data.price }).then(res => {
      const { id } = res.data
      return id
    })

  }

  const onApprove = async data => {
    console.log('Approve');
    return fetch(`${backendIP}/payment/transaction-complete`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderID: data.orderID,
      }),
    })
      .then((response) => response.json())
      .then((orderData) => {

        console.log(
          "Capture result",
          orderData,
          JSON.stringify(orderData, null, 2)
        );
        window.alert("Your Payment is Successful")
        sendMail(state.user,state.data)
        BookTours()
        navigate('/thank-you')


      });
  }

  return (
    <div className='w-full min-h-screen flex justify-center items-center'>
      <div className="min-h-[24rem] w-[640px]  p-2 bg-primary space-y-5">
        <p className='text-lg font-medium text-center text-white '>Payment</p>
        <Details />
        <div className=" ">

          <PayPalButton
            className='w-full'
            createOrder={createOrder}
            onApprove={onApprove}
          />
        </div>
      </div>

    </div>
  )
}

export default Payment