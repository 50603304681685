import React from 'react'
import logo from '../asset/images/shared/footer/logo.png'


// tours
import evening from '../asset/images/shared/footer/tours/evening.jpg'
import henParty from '../asset/images/shared/footer/tours/henparty.jpg'
import shared from '../asset/images/shared/footer/tours/shared.jpg'
import special from '../asset/images/shared/footer/tours/special.jpg'
import large from '../asset/images/shared/footer/tours/large.jpg'
import privateTour from '../asset/images/shared/footer/tours/private.jpg'

function Footer() {
  return (
    <div className=' px-[5%] py-[2%] bg-primary text-white space-y-3'>
      <div className="flex flex-col md:flex-row w-full justify-center gap-5">
        <div className="w-full md:w-[28%] flex md:inline-block justify-center  ">
          <img src={logo} alt="" className='w-20' />
        </div>
        <div className="w-full md:w-[28%]  space-y-5">
          <p>TOURS</p>
          <div className="flex  justify-center items-center flex-wrap gap-3 text-sm">
            <Tours image={evening} name={<p className='text-center'>Evening <br /> Punting</p>} />
            <Tours image={henParty} name={<p className='text-center'>Hen Party</p>} />
            <Tours image={shared} name={<p className='text-center'>Shared <br /> Punting</p>} />
            <Tours image={special} name={<p className='text-center'>Special <br /> Occasion</p>} />
            <Tours image={large} name={<p className='text-center'>Large <br /> Group</p>} />
            <Tours image={privateTour} name={<p className='text-center'>Private <br /> Punting</p>} />
          </div>
        </div>
        <div className="w-full md:w-[28%] space-y-5">
          <p className='font-medium'>CONTACT INFO</p>

          <p className='text-sm text-[#ABABAB]'>Address : Landing Stage <br />
            Thompsons Ln, Cambridge CB5 8AQ <br />
            United Kingdom</p>

          <div className='text-sm text-[#ABABAB]'>
            <p>Phone : 01223 782503</p>
            <p>info@punting-cambridge.com</p>
          </div>

        </div>
      </div>
      <p className='py-10 text-center'>Copyright 2023 Punting Cambridge, All Right Reserved</p>
    </div>
  )
}

export default Footer


const Tours = ({ name, link, image }) => {

  return (
    <div className="h-24 w-24 relative overflow-hidden group ">
      <img src={image} alt="" className='w-full h-full group-hover:scale-125 duration-500' />
      <div className="absolute top-0 left-0 h-full w-full flex justify-center items-center bg-black/40">
        <p className='text-center'>{name}</p>
      </div>
    </div>
  )
}