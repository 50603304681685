import React from 'react'

import logo from '../asset/images/shared/header/logo.png'
import { Email, Facebook, Instagram, LocalPhone } from '@mui/icons-material'

function Header() {
    return (
        <header className=''>
            <div className="h-16 w-full px-[10%] flex justify-between items-center bg-primary text-white">
                <div className="flex gap-5">
                    <div className='flex gap-2'>
                        <LocalPhone style={{ fontSize: '1rem' }} className='relative top-1' />
                        <p> 01223 782503 </p>
                    </div>

                    <div className='hidden md:flex gap-2'>
                        <Email style={{ fontSize: '1rem' }} className='relative top-1' />
                        <p> info@punting-cambridge.com </p>
                    </div>

                </div>
                <div className="flex gap-5 text-blue-600">
                    <Facebook style={{ fontSize: '1rem' }} />
                    <Instagram style={{ fontSize: '1rem' }} />
                </div>
            </div>
            <div className="flex justify-center md:justify-between items-center h-[88px] bg-white w-full px-[10%]">
                <img src={logo} className='w-24' alt="" />
                <ul className='hidden md:flex items-center   text-sm gap-11 text-[#333333]'>
                    <li className='cursor-pointer '><a href="https://punting-cambridge.com/"> HOME </a></li>
                    <li className='cursor-pointer '><a href="https://punting-cambridge.com/"> ABOUT </a></li>
                    <li className='cursor-pointer '><a href="https://punting-cambridge.com/"> TOURS </a></li>
                    <li className='cursor-pointer '><a href="https://punting-cambridge.com/"> BLOG </a></li>
                    <li className='cursor-pointer '><a href="https://punting-cambridge.com/"> CONTACT US </a></li>
                </ul>
                <div className="hidden md:flex"></div>
            </div>
        </header>
    )
}

export default Header